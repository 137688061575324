<template>
  <div class="content">
    <div class="handle-box">
      <el-button type="primary" icon="el-icon-circle-plus-outline" class="mr20" @click="$refs.formDialog.formVisible = true">添加服务人员</el-button>
    </div>
    <!-- 列表 -->
    <el-table :data="list" @sort-change="changeTableSort">
      <el-table-column prop="name" label="姓名" align="left" width="80"></el-table-column>
      <el-table-column prop="phone" label="电话" align="left"></el-table-column>
	  <el-table-column prop="typeIdList" label="服务" align="center" width="100">
		<div slot-scope="scope" style="display: flex;flex-direction: column;">
			<span v-for="item in scope.row.typeIdList">{{item.name}}</span>
		</div>
	  </el-table-column>
	  <el-table-column prop="image" label="图片" align="center" width="120">
	    <img  style="width:100px;height:100px;border-radius:4px;"  slot-scope="scope" :src="scope.row.image" mode="aspectFill"></img>
	  </el-table-column>
	  <el-table-column prop="auth" label="认证" align="center" width="80">
	    <span slot-scope="scope">{{ scope.row.auth ? '是' : '否' }}</span>
	  </el-table-column>
	  <el-table-column prop="vip" label="VIP" align="center" width="80">
	    <span slot-scope="scope">{{ scope.row.vip ? '是' : '否' }}</span>
	  </el-table-column>
	  <el-table-column prop="duty" label="专职" align="center" width="80">
	    <span slot-scope="scope">{{ scope.row.duty ? '是' : '否' }}</span>
	  </el-table-column>
	  <el-table-column prop="company" label="公司名称" align="left"></el-table-column>
	  <el-table-column prop="num" label="服务人数" align="left"></el-table-column>
	  <el-table-column prop="status" label="是否启用" width="80" align="center">
	    <el-switch slot-scope="scope" v-model="scope.row.status" :width="36" :active-value="1" :inactive-value="0" @change="stateChange(scope.$index, scope.row)"></el-switch>
	  </el-table-column>
      <el-table-column label="操作" width="220" align="center">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" @click="edit(scope.row)">编辑</el-button>
          <el-button type="danger" icon="el-icon-delete" @click.native.prevent="toDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
	
	<!-- 分页 -->
	<div class="pagination">
	  <el-pagination background @current-change="pagination" layout="total,prev,pager,next,jumper" :current-page="page" :page-size="pageLimit" :total="totalSize"></el-pagination>
	</div>
    <!-- 新增、编辑 -->
    <service-manage ref="formDialog" @refreshData="loadList"></service-manage>
  </div>
</template>

<script>
import serviceManage from './service-manage';
import http from '@/utils/cloud';
export default {
  components: {
    serviceManage,
  },
  data() {
    return {
      list: [],
      project:localStorage.getItem('mall-project'),
	  page: 1,
	  pageLimit: 20,
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    async loadList() {
	  const { page, pageLimit, orderBy } = this;
      const response = await http.POST('/jw-admin', {
        module: 'service',
        operation: 'getServiceList',
        project: JSON.parse(this.project)._id,
		offset: (page - 1) * pageLimit,
		limit: pageLimit,
      });
      this.list = response.data;
	  this.totalSize = response.affectedDocs;
      this.loaded = true;
    },
    /* 启用 | 禁用 */
    async stateChange(index, item) {
      const response = await http.POST('/jw-admin', {
        module: 'service',
        operation: 'setStatus',
        id: item._id,
        status: item.status
      });
      if (response.updated !== 1) {
        this.$message.error(response.msg || '操作失败');
        item.status = item.status == 1 ? 0 : 1;
      }
    },
    /* 删除 */
    toDelete(index, item) {
      this.$confirm(`是否要删除服务：${item.name}`, '删除提示', {
        confirmButtonText: '删除',
        type: 'warning',
      })
        .then(async () => {
          const response = await http.POST('/jw-admin', {
            module: 'service',
            operation: 'deleteService',
            id: item._id
          });

          if (response.status === 1) {
            this.$message.success('删除成功');
            this.list.splice(index, 1);
			this.loadList();
          } else {
            this.$message.error(response.msg);
          }
        })
        .catch(() => {});
    },
    /* 编辑 */
    edit(item) {
      this.$refs.formDialog.formData = JSON.parse(JSON.stringify(item));
      this.$refs.formDialog.formVisible = true;
    },
	// 当前页数改变
	pagination(e) {
	  this.page = e
	  this.loadList()
	},
  },
};
</script>

<style scoped lang="scss"></style>
