<template>
  <div>
    <el-dialog class="dialog" :title="formTitle" :close-on-click-modal="false" :visible.sync="formVisible">
      <el-form ref="dataForm" :rules="rules" :model="formData" label-position="left" label-width="100px" style="width: 400px; margin-left: 50px">
		<el-form-item label="姓名" prop="name">
		  <el-input v-model="formData.name" clearable maxlength="60" placeholder="请输入姓名" />
		</el-form-item>
		<el-form-item label="电话" prop="phone">
		  <el-input v-model="formData.phone" clearable maxlength="11" placeholder="请输入联系电话" />
		</el-form-item>
		<el-form-item label="图片" prop="image">
		  <Imgpond v-model="formData.image" :count="1" />
		</el-form-item>
		<el-form-item label="是否认证" prop="auth">
		  <el-switch class="has-text" style="margin-left: 6px" v-model="formData.auth" :width="36" :active-value="1" :inactive-value="0" active-text="是" inactive-text="否">></el-switch>
		</el-form-item>
		<el-form-item label="是否VIP" prop="vip">
		  <el-switch class="has-text" style="margin-left: 6px" v-model="formData.vip" :width="36" :active-value="1" :inactive-value="0" active-text="是" inactive-text="否">></el-switch>
		</el-form-item>
		<el-form-item label="是否专职" prop="duty">
		  <el-switch class="has-text" style="margin-left: 6px" v-model="formData.duty" :width="36" :active-value="1" :inactive-value="0" active-text="是" inactive-text="否">></el-switch>
		</el-form-item>
		<el-form-item label="公司名称" prop="company">
		  <el-input v-model="formData.company" clearable placeholder="请输入公司名称" />
		</el-form-item>
		<el-form-item label="服务分类" prop="typeIdList">
		  <el-checkbox-group v-model="formData.typeIdList">
		    <el-checkbox @change="change($event,item)" v-for="item in typeList" :label="item._id" :key="item._id">{{item.name}}</el-checkbox>
		  </el-checkbox-group>
		</el-form-item>
		<el-form-item label="服务人数" prop="num">
		  <el-input v-model="formData.num" type="number" clearable placeholder="请输入服务人数" />
		</el-form-item>
        <el-form-item label="是否启用" prop="status">
          <el-switch class="has-text" style="margin-left: 6px" v-model="formData.status" :width="36" :active-value="1" :inactive-value="0" active-text="启用" inactive-text="禁用">></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="padding-left: 140px">
        <el-button size="medium" class="confirm-btn" @click="formVisible = false">取消</el-button>
        <el-button size="medium" class="confirm-btn" type="primary" @click="submit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import http from '@/utils/cloud'
import imageUpload from '@/components/image-upload/image-upload'

export default {
  data() {
    return {
      project: localStorage.getItem('mall-project'),
      formVisible: false, //表单显示状态
      formData: {
		typeIdList: [],
	  }, //表单数据
      typeList: [],
	  list:[],
      rules: {
		typeIdList: [{ type: 'array', required: true, message: '请至少选择一个服务分类', trigger: 'change' }],
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
        company: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
        num: [{ required: true, message: '请输入服务人数', trigger: 'blur' }],
        auth: [{ required: true }],
        duty: [{ required: true }],
        vip: [{ required: true }],
        status: [{ required: true }],
        image: [{ required: true, message: '请上传图片', trigger: 'blur' }],
      }
    }
  },
  computed: {
    formTitle() {
      return this.formData._id ? '修改服务' : '添加服务'
    }
  },
  watch: {
    formVisible(state) {
      if (state) {
        //默认数据
        if (!this.formData._id) {
          this.formData = {
            status: 1,
            auth: 0,
            duty: 0,
            vip: 0,
			typeIdList:[]
          }
		  this.list = []
        }else{
		  this.list = this.formData.typeIdList;
		  this.formData.typeIdList = this.formData.typeIdList.map(item=>{
			return item.id
		  })
		}
      } else {
        this.formData = {
			status: 1,
			auth: 0,
			duty: 0,
			vip: 0,
			typeIdList:[]
		}
      }
    },
    formData(val) {
    }
  },

  created() {
    this.loadTypeList()
  },
  methods: {
	change(e,item){
		if(e){
			const res = this.formData.typeIdList.findIndex(i => i == item._id);
			const data = {}
			data.id = this.formData.typeIdList[res]
			data.name = item.name
			this.list.push(data);
		}else{
			const res = this.list.findIndex(i => i.id == item._id);
			this.list.splice(res,1);
		}
	},
    //加载分类
    async loadTypeList() {
      const response = await http.POST('/jw-admin', {
        module: 'service',
        operation: 'getCategoryList',
        project: JSON.parse(this.project)._id,
		status: 1
      })
      this.typeList = response.data
    },
    //提交
    submit() {
      this.$refs.dataForm.validate(async res => {
        if (res === false) {
          return
        }
		this.formData.typeIdList = this.list;
        const response = await http.POST('/jw-admin', {
          module: 'service',
          operation: 'setService',
          project: JSON.parse(this.project)._id,
          ...this.formData
        })
		
        if (response.status === 1) {
          this.$message.success(response.msg)
          this.$emit('refreshData')
          this.formVisible = false
        } else {
          this.$message.error(response.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  & ::v-deep .el-dialog__body {
    padding-bottom: 10px;
  }
  & ::v-deep .el-dialog {
    margin-bottom: 5vh;
    min-width: 880px;
    max-width: 880px;
  }
}
</style>
